import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import Select from 'react-select'
import isUndefined from 'lodash/isUndefined'
import panzoom from 'panzoom'
import toInteger from 'lodash/toInteger'
import uniq from 'lodash/uniq'
import { Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import CONFIG from '../../config'
import Feedback from './feedback'
import KeyValuesForm from './keyValuesForm'
import Layout from '../../components/layouts/Layout'
import RadioButton from '../../components/common/RadioButton'
import Scale, { DEFAULT_SCALE } from './scale'
import formatGroupLabel from '../../components/consultations/GroupLabel'
import { Conditions_conditions } from '../../hasura/graphQlQueries/types/Conditions'
import { Issue } from '../../lib/issue'
import { QueryName } from '../../hasura/queryNames'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { deletePredictionAction, insertPredictionsAction } from '../../hasura/slices/predictions'
import { postSlackMessageAction, usersSelector, UsersState } from '../../hasura/slices/users'
import { predictions_normalized_insert_input } from '../../../types/globalTypes'

import {
  checkTrainingIterationCompletedAction,
  fetchMedicalImageAction,
  fetchTrainingIterationAction,
  medicalImagesSelector,
  MedicalImagesState,
  preloadMedicalImagesAction,
} from '../../hasura/slices/medical-images'

import {
  conditionOptions,
  emojiFor,
  filterConditions,
  keyForAwsS3Url,
  preloadImage,
  searchQueryParams,
  speciesFor,
  trackHotjarEvent,
} from '../../lib/helpers'

// @ts-ignore
import nextIcon from '../../lib/images/next-right-dark.png'

enum GradeType {
  DefaultScale = 'default scale',
  Binary = 'binary',
}

export default function BinaryTaggingComponent() {
  const dispatch = useDispatch()

  const elementRef = useRef(null)
  const panzoomRef = useRef(null)

  const [displayFeedbackModal, setDisplayFeedbackModal] = useState(false)
  const [displayTrainingIterationPrompt, setDisplayTrainingIterationPrompt] = useState(false)
  const [flip, setFlip] = useState(false)
  const [gradeType, setGradeType] = useState(GradeType.DefaultScale)
  const [idx, setIdx] = useState<number | undefined>(0)
  const [isInsertingPrediction, setIsInsertingPrediction] = useState(false)
  const [keyDown, setKeyDown] = useState<number | undefined>()
  const [medicalImageIds, setMedicalImageIds] = useState<number[]>([])
  const [rotate, setRotate] = useState(0)

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { medicalImage, trainingIteration, isQuerying, binaryTaggingPresignedUrls }: MedicalImagesState = useSelector(
    medicalImagesSelector
  )

  const [otherConditions, setOtherConditions] = useState<Conditions_conditions[]>([])

  const { conditions }: ConsultationsState = useSelector(consultationsSelector)

  const isScale = trainingIteration?.type === 'scale' || !trainingIteration?.condition.key_values_json
  const isBinary = gradeType === GradeType.Binary

  const prediction = medicalImage?.predictions_normalizeds.find(
    (p) =>
      p.vet_id === user?.id && trainingIteration?.id === p.training_iteration_id && p.condition?.id === trainingIteration.condition.id
  )

  const presignedTaggingImageUrl =
    isQuerying[QueryName.FetchMedicalImage] || !medicalImage?.aws_s3_url
      ? undefined
      : binaryTaggingPresignedUrls.find(
          (b) => keyForAwsS3Url(medicalImage?.aws_s3_url) && b.includes(keyForAwsS3Url(medicalImage.aws_s3_url)!)
        )

  useEffect(() => {
    const instance = panzoomRef?.current
    if (!presignedTaggingImageUrl || !instance) return

    // @ts-ignore
    instance.moveTo(0, 0)
    // @ts-ignore
    instance.zoomAbs(0, 0, 1)
  }, [presignedTaggingImageUrl])

  useEffect(() => {
    setIsInsertingPrediction(false)
    setRotate(0)
    setFlip(false)
  }, [medicalImage])

  useLayoutEffect(() => {
    if (isUndefined(idx) || !medicalImageIds.length || !elementRef.current) return

    // @ts-ignore
    panzoomRef.current = panzoom(elementRef.current!, { minZoom: 0.25, maxZoom: 4 })

    return () => {
      // @ts-ignore
      panzoomRef.current.dispose()
    }
  }, [idx, medicalImageIds])

  useEffect(() => {
    const id = parseInt(searchQueryParams('i') || '', 10)
    if (!accessToken || !user || !id) return

    trackHotjarEvent('started_tagging_set')
    dispatch(fetchTrainingIterationAction(accessToken, id))
    return () => {
      dispatch(checkTrainingIterationCompletedAction(accessToken, id, user))
    }
  }, [accessToken, user])

  useEffect(() => {
    const size = 5
    if (idx === undefined || idx % size !== 0 || !binaryTaggingPresignedUrls.length) return

    binaryTaggingPresignedUrls.slice(idx, idx + size).forEach(preloadImage)
  }, [idx, binaryTaggingPresignedUrls])

  useEffect(() => {
    if (!trainingIteration || !user) return

    const medicalImageIds = trainingIteration.medical_image_ids_denormalized.split(',').map(toInteger)
    const idx = Math.max(
      0,
      medicalImageIds.findIndex(
        (id) => !trainingIteration.predictions_normalizeds.some((p) => p.vet_id === user?.id && p.medical_images_id === id)
      )
    )
    if (idx === 0 && trainingIteration.condition.training_iteration_prompt) setDisplayTrainingIterationPrompt(true)
    setIdx(idx)
    setMedicalImageIds(medicalImageIds)
    const yoloLabel = trainingIteration.condition.ml_config?.yolo_label
    dispatch(preloadMedicalImagesAction(accessToken, medicalImageIds, yoloLabel))
    setGradeType(trainingIteration.condition.grade_type as GradeType)
    if (idx === 0) {
      dispatch(
        postSlackMessageAction(
          `${user.display_name} started ${trainingIteration.condition.display_name} ${trainingIteration.species} tagging set (ID: ${trainingIteration.id}).`
        )
      )
    }
  }, [trainingIteration, user])

  useEffect(() => {
    if (!accessToken || !medicalImageIds.length || isUndefined(idx)) return

    dispatch(fetchMedicalImageAction(accessToken!, medicalImageIds[idx]))
  }, [accessToken, medicalImageIds, idx])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [medicalImage])

  const handleKeyDown = (e: any) => {
    const n = parseInt(e.key, 10)
    if (e.repeat || gradeType === GradeType.Binary || !DEFAULT_SCALE.includes(n)) return

    setKeyDown(n)
  }

  const handleKeyUp = (e: any) => {
    const n = parseInt(e.key, 10)
    if (gradeType === GradeType.Binary || !DEFAULT_SCALE.includes(n)) return

    handlePrediction(n)
    setKeyDown(undefined)
  }

  const handleKeyValuePrediction = (result: [string, string[]][]) => {
    const data: predictions_normalized_insert_input = {
      condition_id: trainingIteration?.condition.id,
      display_name: trainingIteration?.condition.display_name,
      grade: undefined,
      issue: undefined,
      key_values_json: Object.fromEntries(result),
      medical_images_id: medicalImage?.id,
      training_iteration_id: trainingIteration?.id,
      type: 'specialist-medical_image-key_values_tagger',
      vet_id: user?.id,
    }
    insertPrediction(data)
  }

  const insertPrediction = async (newPrediction: predictions_normalized_insert_input) => {
    setIsInsertingPrediction(true)
    if (prediction) await dispatch(deletePredictionAction(accessToken, prediction.id))
    await dispatch(insertPredictionsAction(accessToken, [newPrediction]))
    handleGoNext()
  }

  const handlePrediction = async (grade?: number, binary?: boolean, issue?: Issue) => {
    let display_name
    if (!issue) {
      display_name = `${(grade ? grade > 1 : binary) ? '' : 'NOT '}${trainingIteration?.condition.display_name}`
    }
    const data: predictions_normalized_insert_input = {
      condition_id: trainingIteration?.condition.id,
      display_name,
      grade,
      issue,
      medical_images_id: medicalImage?.id,
      training_iteration_id: trainingIteration?.id,
      type: 'specialist-medical_image-binary_tagger',
      vet_id: user?.id,
    }
    insertPrediction(data)
  }

  const handleOtherPrediction = async (condition: Conditions_conditions) => {
    const otherPrediction: predictions_normalized_insert_input = {
      condition_id: condition.id,
      display_name: condition.display_name,
      medical_images_id: medicalImage?.id,
      training_iteration_id: trainingIteration?.id,
      type: 'specialist-medical_image-binary_tagger',
      vet_id: user?.id,
    }
    setOtherConditions(uniq([...otherConditions, condition]))
    await dispatch(insertPredictionsAction(accessToken, [otherPrediction]))
    handlePrediction(undefined, false)
  }

  const handleGoNext = () => {
    if (medicalImageIds[idx! + 1]) {
      setIdx(idx! + 1)
    } else {
      setDisplayFeedbackModal(true)
    }
  }

  const handleGoBack = () => setIdx(idx! - 1)

  const isNetworking = isInsertingPrediction || isQuerying[QueryName.FetchMedicalImage]

  const backEnabled = (idx || 0) > 0 && !isNetworking

  const species = speciesFor(medicalImage?.species || medicalImage?.case?.patient.species)
  const filteredConditions = filterConditions(conditions, species).filter((c) => c.id !== trainingIteration?.condition.id)
  const options = conditionOptions(filteredConditions)

  return (
    <Layout irxMode>
      <Modal
        centered
        className="z-max-1"
        contentClassName="dark-modal"
        fade={false}
        isOpen={displayTrainingIterationPrompt}
        toggle={() => setDisplayTrainingIterationPrompt(false)}
      >
        <ModalHeader>{trainingIteration?.condition.display_name}</ModalHeader>

        <ModalBody>
          <p style={{ whiteSpace: 'pre-wrap' }}>{trainingIteration?.condition.training_iteration_prompt}</p>
        </ModalBody>

        <ModalFooter style={{ border: 'none !important' }}>
          <Button className="width-100px" color="primary" onClick={() => setDisplayTrainingIterationPrompt(false)}>
            Okay
          </Button>
        </ModalFooter>
      </Modal>

      <Feedback displayName={trainingIteration?.condition.display_name} id={trainingIteration?.id} isOpen={displayFeedbackModal} />

      {!isUndefined(idx) && medicalImageIds.length && !displayTrainingIterationPrompt ? (
        <div
          className="d-flex flex-column justify-content-between"
          style={{ height: `calc(100vh - ${CONFIG.HEADER_HEIGHT}px)`, outline: 'none' }}
        >
          <div id="binary-tagger-progress" className="z-max-1">
            <div className="flex-center">
              <div className="position-relative z-max-1">
                <img
                  className={`p-1 icon-m ${backEnabled ? 'pointer' : 'opacity-50 pe-none'}`}
                  style={{ transform: 'rotate(180deg)' }}
                  src={nextIcon}
                  onClick={handleGoBack}
                />
              </div>

              <p style={{ minWidth: '' }} className="text-dark-bg text-m mb-0 px-2 text-right single-line">
                Image {idx + 1} of {medicalImageIds.length}
              </p>

              <div className="position-relative z-max-1">
                <img
                  className={`p-1 icon-m ${!isNetworking ? 'pointer' : 'opacity-50 pe-none'}`}
                  src={nextIcon}
                  onClick={handleGoNext}
                />
              </div>
            </div>
          </div>

          <div ref={elementRef} style={{ objectFit: 'contain', height: '60%' }} className="flex-center z-max-2 px-3">
            {presignedTaggingImageUrl && (
              <img
                style={{ transform: `rotate(${rotate}deg)${flip ? ' scaleY(-1)' : ''}` }}
                className={`${isNetworking ? 'opacity-50' : ''} flex-center px-3 py-2 mw-100 mh-100 transition-m`}
                src={presignedTaggingImageUrl}
              />
            )}
          </div>

          <div style={{ bottom: '150px', marginLeft: '40px' }} className="text-center z-max-1 position-fixed">
            <div className="text-dark-bg flex-even text-left width-300px">
              <div className="text-m text-dark-bg">
                <div className="mb-1 d-flex">
                  <p
                    className={
                      trainingIteration?.condition?.training_iteration_prompt
                        ? 'text--primary pointer hover-underline m-0'
                        : 'pe-none m-0'
                    }
                    onClick={() => setDisplayTrainingIterationPrompt(true)}
                  >
                    Labeling {trainingIteration?.condition.display_name}
                  </p>
                </div>

                <div className="mb-1">
                  <p className="m-0">
                    {emojiFor(species)} {medicalImage?.case?.patient.display_name} {medicalImage?.case?.patient.breed}
                  </p>
                </div>

                {/* {medicalImage?.case?.consultation && trainingIteration && (
                  <div className="mb-1 d-flex text-s">
                    <p className="m-0">
                      {sentencesIncludingString(
                        medicalImage.case.consultation.receiving_vet_notes || '',
                        trainingIteration.condition.display_name
                      )
                        .join(' ... ')
                        .slice(0, 200)}
                    </p>

                    <p
                      onClick={() => window.open(`/consultations?i=${medicalImage!.case!.consultation!.id}`, '_blank')}
                      className="text--primary ls-sm pointer underline semibold m-0"
                    >
                      View consult
                    </p>
                  </div>
                )} */}

                {prediction && (
                  <p className="m-0 text-m text--success w-100">
                    {prediction.issue
                      ? 'You labeled this an issue.'
                      : isScale
                      ? `You labeled this ${prediction.grade || (prediction.display_name?.includes('NOT') ? 'NO' : 'YES')}.`
                      : ''}
                  </p>
                )}
              </div>

              <div className="w-100 my-3" style={{ height: '1px', backgroundColor: '#b9b9b9' }} />

              <div className="mb-3">
                <p className="mb-0 semibold pointer text-m mb-2">
                  Another condition (not {trainingIteration?.condition.display_name}) is present?
                </p>

                <Select
                  className="react-select-dark-container z-max-1"
                  classNamePrefix="react-select-dark"
                  controlShouldRenderValue={false}
                  placeholder="Search..."
                  options={options}
                  // @ts-ignore
                  formatGroupLabel={formatGroupLabel}
                  onChange={(option: any) => {
                    const selected = filteredConditions.find((c) => c.id === option.value)
                    if (!selected) return

                    handleOtherPrediction(selected)
                  }}
                />

                {otherConditions.length > 0 && (
                  <div className="mt-3 d-flex gap-10px flex-wrap">
                    {otherConditions.slice(0, 5).map((c, idx) => (
                      <Button onClick={() => handleOtherPrediction(c)} outline size="sm" key={idx}>
                        {c.display_name}
                      </Button>
                    ))}
                  </div>
                )}
              </div>

              <div className="w-100 my-3" style={{ height: '1px', backgroundColor: '#b9b9b9' }} />

              <div className="mb-3">
                <p className="mb-0 semibold pointer text-m">Issue with image?</p>

                <div className="d-flex flex-column z-max-1 gap-5px pt-1">
                  {[Issue.LowQuality, Issue.Positioning, Issue.WrongBodyPart, Issue.Other].map((i) => (
                    <RadioButton key={i} onClick={() => handlePrediction(undefined, undefined, i)} checked={false} label={i} />
                  ))}
                </div>
              </div>

              <div className="w-100 my-3" style={{ height: '1px', backgroundColor: '#b9b9b9' }} />

              <div className="d-flex gap-20px">
                <Button outline size="sm" onClick={() => setFlip(!flip)}>
                  Flip
                </Button>

                <Button outline size="sm" onClick={() => setRotate(rotate === 270 ? 0 : rotate + 90)}>
                  Rotate
                </Button>
              </div>
            </div>

            <div className="min-width-300px flex-even" />
          </div>

          {isScale ? (
            <Scale keyDown={keyDown} handlePrediction={handlePrediction} isBinary={isBinary} isNetworking={isNetworking} />
          ) : (
            <KeyValuesForm
              medicalImageId={medicalImage?.id}
              prediction={prediction}
              save={handleKeyValuePrediction}
              settings={trainingIteration.condition.key_values_json}
            />
          )}
        </div>
      ) : (
        <Spinner color="primary" className="center" />
      )}
    </Layout>
  )
}
